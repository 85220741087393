import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import TextInput from '../../components/app/TextInput';
import Button from '../../components/Button';
import {post} from '../../helpers/api';
import {ERROR_LOGIN, IZIFILL_EMAIL_KEY, IZIFILL_NAME_KEY} from '../../helpers/constants';
import {ORDER_API_URL, REGISTER_URL} from '../../helpers/endpoints';
import {setItemToStorage} from '../../helpers/localStorage';
import {navigateToFill} from '../../helpers/navigation';

const Register = (props) => {
  const {state = {}} = props.location;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onLoginClicked = () => {
    if (email === '' || password === '' || name === '' || confirmPassword === '') {
      setErrorMessage(ERROR_LOGIN.EMPTY_FIELD);
    } else {
      setErrorMessage('');

      if (password === confirmPassword) {
        registerUser();
      } else {
        setErrorMessage(ERROR_LOGIN.CONFIRM_PASSWORD_NOT_MATCH);
      }
    }
  };

  async function registerUser() {
    const url = REGISTER_URL;

    const response = await post(url, {
      name: name, email: email, password: password,
    });
    const data = await response.json();
    if (typeof (data.name) !== 'undefined') {
      setItemToStorage(IZIFILL_NAME_KEY, data.name);
      setItemToStorage(IZIFILL_EMAIL_KEY, data.email);
      postOrderRequest();
      debugger;
      navigateToFill(state);
    } else {
      setErrorMessage(ERROR_LOGIN.SERVER_ERROR);
    }
  }

  const postOrderRequest = () => {
    post(ORDER_API_URL, {
      volumeId: state.waterOption.id,
      temp: state.temp.name,
      waterStationId: state.stationCode,
      userId: email,
    });
  };

  return (
    <div className="d-flex flex-column register mt-10 mx-2">
      <h2>Register</h2>
      <Form>
        <TextInput
          required={true}
          pattern="^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$"
          className="mt-2" title="Name" id="name" type="text"
          onChangeInput={(e) => setName(e.target.value)}
        />
        <TextInput className="mt-2" required={true} title="Email" id="email" type="email" onChangeInput={(e) => setEmail(e.target.value)} />
        <TextInput className="mt-2" required={true} title="Password" id="password" type="password" onChangeInput={(e) => setPassword(e.target.value)} />
        <TextInput className="mt-2" required={true} title="Confirm Password" id="confirmPassword" type="password" onChangeInput={(e) => setConfirmPassword(e.target.value)} />
      </Form>
      <Button onClick={onLoginClicked} className="mt-4 px-7 align-self-center" text="Register" buttonClassName="button-secondary" />

      <small className={`mt-2 align-self-center text-danger ${errorMessage === '' ? 'd-none' : 'd-block'}`}>{errorMessage}</small>
    </div>
  );
};

export default Register;
